<template>
  <div class="navigation-wrapper px-4">
    <nav class="nav-container">
      <div class="logo-container">
        <img
          src="/img/new_tf_logo.png"
          alt="TalentFind Logo"
          class="logo-image"
          @click="$router.push(webURL())"
        />
      </div>

      <div class="nav-items-container">
        <ul
          class="nav-list"
          :class="$route.path.includes('/web/uae') ? 'd-none' : ''"
        >
          <li
            class="nav-item dropdown-parent"
            @mouseenter="showDropdown = 'career_pathway'"
            @mouseleave="showDropdown = ''"
            @click="
              showDropdown =
                showDropdown == 'career_pathway' ? '' : 'career_pathway'
            "
          >
            <span @click="navigateTo('/career-pathway')" class="nav-link">
              Career Pathways
              <i class="dropdown-chevron fas fa-chevron-down"></i>
            </span>

            <transition name="dropdown-fade">
              <div
                v-if="showDropdown === 'career_pathway'"
                class="dropdown-panel"
              >
                <div class="dropdown-arrow"></div>
                <div class="dropdown-grid">
                  <div class="pathway-info">
                    <h3 class="dropdown-heading f-secondary font-25">
                      Career Pathways
                    </h3>
                    <p class="dropdown-description">
                      Whether you’re still studying, stepping into your first
                      role, hitting your career's high notes, or even planning a
                      fulfilling retirement, our focus is on providing you with
                      the opportunities and resources for continuous
                      professional development.
                    </p>
                    <div style="max-width: 30px">
                      <button
                        class="btn btn-primary"
                        @click="$router.push('/web/login')"
                      >
                        Start Your Journey
                      </button>
                    </div>
                  </div>

                  <div class="pathway-options">
                    <h3 class="dropdown-heading f-secondary font-25">
                      Select Your Option
                    </h3>
                    <ul class="options-list">
                      <li
                        class="option-link"
                        @click="navigateTo('/career-pathway/professionals')"
                      >
                        Professionals
                      </li>
                      <li
                        class="option-link"
                        @click="navigateTo('/career-pathway/students')"
                      >
                        Students
                      </li>
                      <li
                        class="option-link"
                        @click="navigateTo('/career-pathway/jobs')"
                      >
                        Jobs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <li
            class="nav-item dropdown-parent"
            @mouseenter="showDropdown = 'professional_development'"
            @mouseleave="showDropdown = ''"
            @click="
              showDropdown =
                showDropdown == 'professional_development'
                  ? ''
                  : 'professional_development'
            "
          >
            <span
              @click="navigateTo('/professional-development')"
              class="nav-link"
            >
              Professional Development
              <i class="dropdown-chevron fas fa-chevron-down"></i>
            </span>

            <transition name="dropdown-fade">
              <div
                v-if="showDropdown === 'professional_development'"
                class="dropdown-panel"
              >
                <div class="dropdown-arrow"></div>
                <div class="dropdown-grid">
                  <div class="pathway-info">
                    <h3 class="dropdown-heading f-secondary font-25">
                      Professional Development
                    </h3>
                    <p class="dropdown-description">
                      Whether you're looking to specialise further, step into
                      leadership roles, or stay abreast of the latest in
                      healthcare, our professional development resources are
                      tailored to support your aspirations.
                    </p>
                  </div>

                  <div class="pathway-options">
                    <h3 class="dropdown-heading f-secondary font-25">
                      Training and Development
                    </h3>
                    <ul class="options-list">
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        Language Courses (Coming Soon)
                      </li>
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        Clinical Skills Training (Coming Soon)
                      </li>
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        Professional Development (Coming Soon)
                      </li>
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        Compliance & Safety (Coming Soon)
                      </li>
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        Leadership and Management (Coming Soon)
                      </li>
                      <li
                        @click="navigateTo('/coming-soon')"
                        class="option-link"
                      >
                        And more…
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <li class="nav-item">
            <span @click="navigateTo('/news')" class="nav-link">News</span>
          </li>
          <!-- <li class="nav-item">
            <span @click="navigateTo('/uae')" class="nav-link">UAE</span>
          </li> -->
        </ul>

        <button class="btn btn-primary m-0" @click="goToRegister()">
          Register/Login
        </button>
        <button class="btn btn-icon m-0" @click="$router.push(webURL())">
          <i class="fa-solid fa-house text-white cursor-pointer"></i>
        </button>
      </div>

      <!-- Mobile Navigation -->
      <div class="mobile-nav-toggle">
        <div class="flex items-center">
          <button
            class="btn btn-primary me-3 mr-2 mt-0 bg-blue-500 text-white px-4 py-2 rounded1"
            @click="goToRegister()"
          >
            Register/Login
          </button>
          <button
            class="btn btn-icon me-3 mt-0 bg-blue-500 text-white px-4 py-2 rounded1"
            style="min-width: 50px !important"
            @click="$router.push(webURL())"
          >
            <i class="fa-solid fa-house text-white cursor-pointer m-0 p-0"></i>
          </button>
          <!-- Menu Icon -->
          <button class="btn btn-outline ml-2" @click="toggleSidebar">
            <i class="fas fa-bars text-second"></i>
          </button>
        </div>
      </div>
    </nav>

    <!-- Sidebar -->
    <div class="mobile-nav-toggle">
      <div :class="['sidebar', { active: showSidebar }]">
        <button class="close-btn" @click="toggleSidebar">&times;</button>
        <ul class="list-unstyled mt-5">
          <li>
            <div class="d-flex justify-content-start align-items-center">
              <a
                href="#"
                class="text-white d-flex justify-content-between"
                @click="navigateTo('/career-pathway')"
              >
                Career Pathways
              </a>
              <span @click="toggleSubMenu('career')">&#x276F;</span>
            </div>
            <ul v-if="activeSubMenu === 'career'" class="submenu">
              <li @click="navigateTo('/career-pathway/professionals')">
                Professionals
              </li>
              <li @click="navigateTo('/career-pathway/students')">Students</li>
              <li @click="navigateTo('/career-pathway/jobs')">Jobs</li>
            </ul>
          </li>
          <li>
            <div class="d-flex justify-content-start align-items-center">
              <a
                href="#"
                class="text-white d-flex justify-content-between"
                @click="navigateTo('/professional-development')"
              >
                Professional Development
              </a>
              <span @click="toggleSubMenu('development')">&#x276F;</span>
            </div>
            <ul v-if="activeSubMenu === 'development'" class="submenu">
              <li @click="navigateTo('/coming-soon')" class="text-white">
                Language Courses (Coming Soon)
              </li>
              <li @click="navigateTo('/coming-soon')" class="text-white">
                Clinical Skills Training (Coming Soon)
              </li>
              <li @click="navigateTo('/coming-soon')" class="text-white">
                Professional Development (Coming Soon)
              </li>
              <li @click="navigateTo('/coming-soon')" class="text-white">
                Compliance & Safety (Coming Soon)
              </li>
              <li @click="navigateTo('/coming-soon')" class="text-white">
                Leadership and Management (Coming Soon)
              </li>
              <li @click="navigateTo('/coming-soon')" class="text-white">
                And more…
              </li>
            </ul>
          </li>
          <li @click="navigateTo('/news')" class="text-white ml-2 my-2">
            News
          </li>
          <!-- <li @click="navigateTo('/uae')" class="text-white ml-2 my-4">UAE</li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { routeToWebRegister, webURL } from "../../helpers/helper";

export default {
  data() {
    return {
      showDropdown: "",
      showSidebar: false,
      activeSubMenu: null,
      webURL,
    };
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      this.activeSubMenu = null;
    },
    toggleSubMenu(menu) {
      this.activeSubMenu = this.activeSubMenu === menu ? null : menu;
    },
    navigateTo(url) {
      let path = `/web${url}`;
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      } else {
        this.$router.replace(path);
      }

      this.toggleSidebar();
    },
    goToRegister() {
      routeToWebRegister(this.$router);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        const wrapper = document.querySelector(".c-wrapper");
        const main = document.querySelector(".c-wrapper .c-main");

        if (wrapper) {
          wrapper.style.setProperty("margin", "0px", "important");
        }
        if (main) {
          main.style.setProperty("padding", "0px", "important");
        }
      }, 200);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";

.mobile-nav-toggle {
  display: none;
}

.navigation-wrapper {
  font-family: $font-primary;
  font-size: 18px;
  padding: 17px;
  background: white;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.logo-image {
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 1264px) {
    height: 30px;
  }
}

.nav-items-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-list {
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  position: relative;
  padding: 0.5rem 0;
}

.nav-link {
  color: #2a2a2a;
  font-weight: 600;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    color: #1a115a;
    text-decoration: underline;
  }
}

.dropdown-panel {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  width: 100vw;
  background: $dark-primary;
  border-radius: 40px;
  padding: 2rem;
  margin-top: 1rem;
  z-index: 1000;
}

.dropdown-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 11px solid $dark-primary;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  color: white;
}

.pathway-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.dropdown-heading {
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
}

.dropdown-description {
  line-height: 1.6;
  opacity: 0.9;
}

.cta-button {
  align-self: flex-start;
  padding: 0.75rem 1.5rem;
  background: #ffffff;
  color: $dark-primary;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f0f0f0;
    transform: translateY(-2px);
  }
}

.options-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0;
  margin: 0;
}

.option-link {
  color: white;
  text-decoration: none;
  list-style: none;
  padding: 0.5rem 0;
  transition: opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
}

.auth-button {
  background: $secondary;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: darken($secondary, 10%);
    transform: translateY(-2px);
  }
}

// .dropdown-fade-enter-active,
// .dropdown-fade-leave-active {
//   transition: opacity 0.2s ease, transform 0.2s ease;
// }

// .dropdown-fade-enter-from,
// .dropdown-fade-leave-to {
//   opacity: 0;
//   transform: translateX(-50%) translateY(-10px);
// }

@media (max-width: 1024px) {
  .nav-items-container {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: $dark-primary;
    color: white;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  .sidebar.active {
    transform: translateX(0);
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  .submenu {
    padding-left: 20px;
    list-style: none;
  }
  .submenu li {
    margin-top: 5px;
  }

  a {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    text-decoration: none !important;
    padding: 10px !important;
  }
}
</style>
