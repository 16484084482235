<template>
  <div>
    <!-- 1 -->
    <section class="main text-white">
      <div
        class="home-section d-flex flex-column justify-content-between flex-md-row mb-5 pt-5"
      >
        <div
          class="col-12 col-md-6 d-flex flex-column justify-content-start align-items-start p-3"
        >
          <h1 class="f-secondary t-secondary p-2">
            Transforming Healthcare Careers
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            Welcome to the only AI-powered platform dedicated to your health and
            social care career. Whether you’re a carer, in training or fully
            qualified, we bring you everything you need to maintain or upskill
            and advance your career, all over the world.
          </div>
          <div class="d-flex justify-content-start p-2">
            <button
              class="btn btn-primary border-radius-12 px-2 p-3"
              @click="$router.push('/web/login')"
            >
              Start Your Journey
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end">
          <img
            src="../../../public/img/stm/pattern.svg"
            class="img-fluid z-1 right-corner img-right"
          />
          <img
            src="../../../public/img/stm/landing-img.png"
            class="img-fluid z-2"
          />
        </div>
      </div>
    </section>

    <!-- 2 -->
    <section class="main my-md">
      <h5 class="text-second text-center">WHY JOIN TALENTFIND?</h5>
      <div class="text-center">
        <h2 class="f-secondary text-gradient-2 px-200">
          One platform built around your career needs
        </h2>
        <p>
          Bringing you the tools to intelligently manage your career, all in one
          place.
        </p>
      </div>

      <div class="col-md-12 col-sm-11 mx-auto">
        <div class="row gx-5 mt-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Create your digital Career Passport
            </p>
            Your TalentFind profile will help recruiters and organisations see
            all your skills, experience and education history instantly.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Secure document storage
            </p>
            Safely store all your health career documents in one place for the
            whole of your career.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Language training and upskilling
            </p>
            We’ve partnered with leading language training schools and
            universities to bring you access to accredited language training,
            upskilling, E-learning, placement and funding opportunities.
          </div>
        </div>
        <div class="row gx-5 mt-md-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Access a global job board
            </p>
            Explore health and social care opportunities all over the world.
            TalentFind connects you with regional and international roles that
            suit your experience.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Personalised career mapping
            </p>
            Because we have a global knowledge of healthcare training pathways,
            we can support you on your journey to growing your career, evolving
            our recommendations as you develop to align with your professional
            goals.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Simplify your health career
            </p>
            Where other platforms make managing your health career feel
            complicated, we keep things simple – just one easy-to-use platform
            dedicated to your future in health or social care.
          </div>
        </div>
      </div>
    </section>

    <!-- 3 -->
    <section class="main container-fluid">
      <div
        class="d-flex justify-content-center align-items-center ml-5 flex-column mb-3"
      >
        <h5 class="text-second">HOW IT WORKS</h5>
        <div class="text-center col-md-6 col-12">
          <h2 class="f-secondary text-gradient-2 text-gradient-3">
            Your Journey with TalentFind
          </h2>
        </div>
        <p class="col-md-8 col-12 text-center">
          Our mission is to make your journey as simple as possible, so you can
          spend less time searching, and more time developing your healthcare
          career.
        </p>
      </div>
      <!-- Starts -->
      <div class="row my-5 d-flex justify-content-between">
        <!-- Left -->
        <div class="col-md-5 ml-5 text-start order-md-1">
          <div class="d-flex justify-content-center">
            <img
              src="../../../public/img/stm/tick_mark.svg"
              class="img-fluid mr-4"
              style="width: 40px; height: 40px"
            />
            <div class="">
              <h2 class="f-secondary text-dark-primary font-25 bold">
                Register for free and set your goals
              </h2>
              <p class="mb-0">
                Create your profile and share your career aspirations – whether
                you’re a student in training or a professional looking to make
                your next big move. Set your profile as public to make yourself
                visible to hiring organisations, or select private to browse
                training and other resources until you’re ready to make a move.
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mt-5">
            <img
              src="../../../public/img/stm/tick_mark.svg"
              class="img-fluid mr-4"
              style="width: 40px; height: 40px"
            />
            <div>
              <h2 class="f-secondary text-dark-primary font-25 bold">
                Explore your personalised pathway
              </h2>
              <p class="mb-0">
                Our AI-powered platform will recommend roles, upskilling
                opportunities and training courses that meet your unique goals
                with 100% accuracy.
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mt-5">
            <img
              src="../../../public/img/stm/tick_mark.svg"
              class="img-fluid mr-4"
              style="width: 40px; height: 40px"
            />
            <div>
              <h2 class="f-secondary text-dark-primary font-25 bold">
                Take the next steps
              </h2>
              <p class="mb-0">
                Apply to jobs, enrol on courses and connect with your community.
                As your career progresses, keep your profile updated and we’ll
                be there with fresh recommendations every step of the way.
              </p>
            </div>
          </div>
        </div>

        <!-- Right -->
        <div class="col-md-6 p-768 text-center order-md-2 mb-5 mb-md-0">
          <img
            class="img-fluid border-radius-40"
            src="../../../public/img/stm/work2.png"
            alt="Image Description"
          />
        </div>
      </div>
      <!-- Ends -->
      <div class="text-center">
        <button class="btn btn-primary" @click="goToRegister()">
          Register Now
        </button>
      </div>
    </section>

    <!-- 4 -->
    <VideoSection />
    <ArticlesSection />
    <FutureSection />
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import VideoSection from "./VideoSection";
import ArticlesSection from "./ArticlesSection.vue";
import FutureSection from "./FutureSection";
import NewFooter from "./NewFooter.vue";
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";
import { routeToWebRegister } from "../../helpers/helper";
import { mapActions } from "vuex";

export default {
  components: {
    VideoSection,
    ArticlesSection,
    FutureSection,
    NewFooter,
    ScrollToTop,
  },
  methods: {
    ...mapActions(["scrollTop"]),
    goToRegister() {
      routeToWebRegister(this.$router);
    },
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
